export function randomElement<T>(array: Array<T>): T {
  return array[Math.floor(Math.random() * array.length)]
}

export function weightedRandomElement<T>(array: Array<T>, weights: number[]): T {
  const totalWeight = weights.reduce((a, b) => a + b)
  const r = Math.random() * totalWeight
  let s = 0
  for (let i = 0; i < array.length; i++) {
    s += weights[i]
    if (s >= r) {
      return array[i]
    }
  }
  return array[array.length - 1] // Should not happen
}

export function shuffle<T>(arr: T[]) {
  for (let i = 0; i < arr.length; i++) {
    const j = i + Math.floor(Math.random() * (arr.length - i))
    const tmp = arr[i]
    arr[i] = arr[j]
    arr[j] = tmp
  }
}

export function enumValues<E>(e: any): E[] {
  return Object.keys(e).map(k => e[k] as E).filter(v => typeof v === 'number')
}

export function def(x: any) {
  return typeof x !== 'undefined'
}

export function randomAssignment<K extends string | number | symbol, V>(keys: K[], values: V[]): Record<K, V> {
  if (keys.length != values.length) {
    throw new Error('keys and values must have same length')
  }

  const shuffled = values.slice(0)
  shuffle(shuffled)

  const out: Partial<Record<K, V>> = {}
  let i = 0
  for (const key of keys) {
    out[key] = shuffled[i++]
  }
  return out as Record<K, V>
}

export function constMap<K extends string | number | symbol, V>(keys: K[], value: V): Record<K, V> {
  const out: Partial<Record<K, V>> = {}
  for (const key of keys) {
    out[key] = value
  }
  return out as Record<K, V>
}

export function assertNever(x: never): never {
    throw new Error("Unexpected object: " + x);
}

export function crossProduct(strings: TemplateStringsArray, ...args: any[]): string[] {
  if (args.length == 0) {
    return [strings[0]]
  }

  const out = []
  const tails = crossProduct(strings.slice(1) as unknown as TemplateStringsArray, ...args.slice(1))
  for (let i = 0; i < args[0].length; i++) {
    for (let j = 0; j < tails.length; j++) {
      out.push(strings[0] + args[0][i] + tails[j])
    }
  }
  return out
}
