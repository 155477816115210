import { State, Gender, Spell, SpellCategory, SpellStrength, Item, Incantation, Effect, Customer } from './state'
import { FLAGS } from './debug'
import { MALE_NAMES, FEMALE_NAMES, SURNAMES } from './names'
import { def, randomElement, weightedRandomElement, enumValues, assertNever, crossProduct } from './utils'

export interface Option {
  html: string
  method: string
  args: any[]
}

export interface IO {
  write(html: string): void
  par(...html: string[]): void
  ask(...options: Option[]): void
}

function opt(html: string, method: string, ...args: any[]): Option {
  return {html, method, args}
}

function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

function subjectPronoun(gender: Gender): string {
  switch (gender) {
    case Gender.MALE: return 'he'
    case Gender.FEMALE: return 'she'
    case Gender.OTHER: return 'it'
  }
}

function objectPronoun(gender: Gender): string {
  switch (gender) {
    case Gender.MALE: return 'him'
    case Gender.FEMALE: return 'her'
    case Gender.OTHER: return 'it'
  }
}

function possessivePronoun(gender: Gender): string {
  switch (gender) {
    case Gender.MALE: return 'his'
    case Gender.FEMALE: return 'her'
    case Gender.OTHER: return 'its'
  }
}

function indefiniteArticle(s: string): string {
  if ('aeoui'.indexOf(s.charAt(0).toLowerCase()) >= 0) {
    return 'an'
  } else {
    return 'a'
  }
}

function addIndefiniteArticle(s: string): string {
  return `${indefiniteArticle(s)} ${s}`
}

function randomApparel(gender: Gender) {
  const adjective = randomElement(['blue', 'black', 'grey', 'beige', 'white', 'dark', 'light', 'fancy', 'old', 'worn'])
  let items = ['coat', 'overcoat', 'shirt', 'jacket', 'pullover', 'vest', 'hat', 'baseball cap']
  if (gender != Gender.FEMALE) {
    items = items.concat(['trenchcoat'])
  }
  if (gender != Gender.MALE) {
    items = items.concat(['blouse', 'dress'])
  }
  const item = randomElement(items)
  let verbs = ['wearing', 'with']
  if (['hat', 'baseball cap'].indexOf(item) < 0) {
    verbs = verbs.concat(['in'])
  }
  const verb = randomElement(verbs)
  return `${verb} ${addIndefiniteArticle(`${adjective} ${item}`)}`
}

function formatPerson(text: string, customer: Customer) {
  return text
    .replace(/SO_NAME/g, customer.significantOtherName)
    .replace(/SO_SUBJ_CAP/g, capitalize(subjectPronoun(customer.significantOtherGender)))
    .replace(/SO_SUBJ/g, subjectPronoun(customer.significantOtherGender))
    .replace(/SO_OBJ_CAP/g, capitalize(objectPronoun(customer.significantOtherGender)))
    .replace(/SO_OBJ/g, objectPronoun(customer.significantOtherGender))
    .replace(/NAME/g, customer.firstName)
    .replace(/SURNAME/g, customer.surname)
    .replace(/SUBJECT_PRONOUN_CAP/g, capitalize(subjectPronoun(customer.gender)))
    .replace(/SUBJECT_PRONOUN/g, subjectPronoun(customer.gender))
    .replace(/OBJECT_PRONOUN_CAP/g, capitalize(objectPronoun(customer.gender)))
    .replace(/OBJECT_PRONOUN/g, objectPronoun(customer.gender))
    .replace(/POSSESSIVE_PRONOUN_CAP/g, capitalize(possessivePronoun(customer.gender)))
    .replace(/POSSESSIVE_PRONOUN/g, possessivePronoun(customer.gender))
}

const NUM_DAYS = FLAGS['oneDay'] ? 1 : 7
const START_MONEY = 20
const TARGET_MONEY = FLAGS['zeroTarget'] ? 0 : 200

interface ItemData {
  name: string
  useTexts: string[]
  longName: string
  longNamePlural: string
  usedName: string
  usedNamePlural: string
  buyCount: number
}

const ITEMS: Record<Item, ItemData> = {
  [Item.CANDLE]: {
    name: 'candle',
    useTexts: crossProduct `You ${['', 'solemnly ', 'carefully ']}${['place', 'put']} a candle on the table and light it.`,
    longName: 'candle',
    longNamePlural: 'candles',
    usedName: 'candle',
    usedNamePlural: 'candles',
    buyCount: 5
  },
  [Item.INCENSE]: {
    name: 'incense',
    useTexts: crossProduct `You ${['', 'solemnly ', 'carefully ']}${['place', 'put']} a stick of incense in a burner and light it.`,
    longName: 'stick of incense',
    longNamePlural: 'sticks of incense',
    usedName: 'stick of incense',
    usedNamePlural: 'sticks of incense',
    buyCount: 5
  },
  [Item.ESSENTIAL_OIL]: {
    name: 'essential oil',
    useTexts: crossProduct `You ${['', 'solemnly ', 'carefully ']}pour a bottle of essential oil out onto the table.`,
    longName: 'bottle of essential oil',
    longNamePlural: 'bottles of essential oil',
    usedName: 'essential oil',
    usedNamePlural: 'puddles of essential oil',
    buyCount: 5
  },
//   [Item.CRYSTAL]: {
//     name: 'crystal',
//     longName: 'crystal',
//     longNamePlural: 'crystals',
//   },
//   [Item.COIN]: {
//     name: 'coin',
//     longName: 'coin',
//     longNamePlural: 'coins',
//   },
//   [Item.CRUCIFIX]: {
//     name: 'crucifix',
//     longName: 'crucifix',
//     longNamePlural: 'crucifixes',
//   },
  [Item.ROSEMARY]: {
    name: 'rosemary',
    useTexts: crossProduct `You ${['', 'slowly ', 'thoroughly ']}crush a sprig of rosemary into a bowl.`,
    longName: 'sprig of rosemary',
    longNamePlural: 'sprigs of rosemary',
    usedName: 'rosemary',
    usedNamePlural: 'piles of ground rosemary',
    buyCount: 5
  },
  [Item.GARLIC]: {
    name: 'garlic',
    useTexts: crossProduct `You ${['', 'slowly ', 'thoroughly ']}crush a bulb of garlic into a bowl.`,
    longName: 'bulb of garlic',
    longNamePlural: 'bulbs of garlic',
    usedName: 'garlic',
    usedNamePlural: 'piles of crushed garlic',
    buyCount: 5
  },
  [Item.SAGE]: {
    name: 'sage',
    useTexts: crossProduct `You ${['', 'slowly ', 'thoroughly ']}crush a sage leaf into a bowl.`,
    longName: 'sage leaf',
    longNamePlural: 'sage leaves',
    usedName: 'sage',
    usedNamePlural: 'piles of crushed sage',
    buyCount: 5
  },
}

interface SpellCategoryData {
  failureText: string
}

const SPELL_CATEGORIES: Record<SpellCategory, SpellCategoryData> = {
  [SpellCategory.LOVE]: {
    failureText: "You smell a faint whiff of perfume. Love is in the air! But nothing else happens.",
  },
  [SpellCategory.MONEY]: {
    failureText: "For a brief moment, you see infinite riches before your eyes. Then reality settles in: the spell has no other effect.",
  },
  [SpellCategory.HEALTH]: {
    failureText: "You suddenly feel fit and energised, but the feeling fades quickly. Nothing else happens.",
  },
}

interface SpellStrengthData {
  adjective: string
  payment: number
}

const SPELL_STRENGTHS: Record<SpellStrength, SpellStrengthData> = {
  [SpellStrength.WEAK]: {
    adjective: 'weak',
    payment: 10,
  },
  [SpellStrength.NORMAL]: {
    adjective: 'regular',
    payment: 20,
  },
  [SpellStrength.STRONG]: {
    adjective: 'powerful',
    payment: 30,
  },
}

interface SpellData {
  name: string
  category: SpellCategory
  personAdjectives: string[]
  reasons: string[]
  rightTexts: Record<SpellStrength, string>
  wrongTexts: Record<SpellStrength, string>
}

const SPELLS: Record<Spell, SpellData> = {
  [Spell.CRUSH]: {
    name: 'crush',
    category: SpellCategory.LOVE,
    personAdjectives: ['gloomy', 'glum'],
    reasons: [
      "I want my neighbour to fall for me.",
      "I have a crush on a coworker, but it's not mutual.",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "“You know,” NAME says, “I haven't actually tried to talk to SO_OBJ. Maybe I just lacked the confidence until now!”",
      [SpellStrength.NORMAL]: "Suddenly, NAME's phone rings. “It's SO_NAME!” SUBJECT_PRONOUN exclaims. “SO_SUBJ_CAP has asked me out on a date!”",
      [SpellStrength.STRONG]: "Suddenly, NAME's phone rings. “It's SO_NAME!” SUBJECT_PRONOUN exclaims. “SO_SUBJ_CAP says SO_SUBJ loves me!”",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "NAME gives you a sweet and slightly sexy smile.",
      [SpellStrength.NORMAL]: "“Did I mention you have lovely eyes?” NAME says.",
      [SpellStrength.STRONG]: "NAME suddenly leans across the table and tries to kiss you. You try to push OBJECT_PRONOUN away. “But… but… I love you! Wait… what just happened?” SUBJECT_PRONOUN stammers.",
    },
  },
  [Spell.RELATIONSHIP]: {
    name: 'relationship',
    category: SpellCategory.LOVE,
    personAdjectives: ['sad-looking', 'downcast'],
    reasons: [
      "The love between me and my partner has faded.",
      "Things are just not as exciting anymore as they used to be.",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "“Actually, it's not all bad, I suppose. We can work it out,” NAME says.",
      [SpellStrength.NORMAL]: "“I'm feeling much better about SO_OBJ already!” NAME cries.",
      [SpellStrength.STRONG]: "“I feel like I've fallen in love all over again! I'm sure SO_SUBJ feels the same way!” NAME exclaims.",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "NAME looks at you with a dreamy look in POSSESSIVE_PRONOUN eyes.",
      [SpellStrength.NORMAL]: "“Huh, I'm just feeling very good about someone. I just don't know who it is…” NAME mumbles.",
      [SpellStrength.STRONG]: "“Hey honey, do you want to go to the movies with me tonight?” NAME asks suddenly. Then a confused look comes over OBJECT_PRONOUN. “Sorry,” SUBJECT_PRONOUN mumbles.",
    },
  },
  [Spell.MARRIAGE]: {
    name: 'marriage',
    category: SpellCategory.LOVE,
    personAdjectives: ['crying', 'teary-eyed'],
    reasons: [
      "We've been together for nine years but my partner SO_NAME still hasn't asked me.",
      "I really want to marry, but my partner SO_NAME seems reluctant.",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "“Well, come to think of it, why don't I ask SO_OBJ myself?” NAME says.",
      [SpellStrength.NORMAL]: "On the table appears a small box. NAME opens it. Inside the box are two rings. “Perfect!” NAME exclaims.",
      [SpellStrength.STRONG]: "NAME's phone rings. “It's SO_NAME! … Hi honey, what's up? … Yes, of course I'll marry you!”",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "You faintly hear some music, but you can't tell where it's coming from. It sounds like Mendelssohn's wedding march.",
      [SpellStrength.NORMAL]: "NAME suddenly looks you in the eyes. “Will you marry… oh, nevermind.”, SUBJECT_PRONOUN breaks off.",
      [SpellStrength.STRONG]: "NAME pushes back POSSESSIVE_PRONOUN chair and gets down on one knee. Only when SUBJECT_PRONOUN realises that SUBJECT_PRONOUN does not have a ring to offer does SUBJECT_PRONOUN snap out of it.",
    },
  },
  [Spell.SALARY]: {
    name: 'salary',
    category: SpellCategory.MONEY,
    personAdjectives: ['scruffy', 'tattered'],
    reasons: [
      "I haven't had a raise for years, while the rent has been rising steadily.",
      "All my coworkers are earning way more than I do for the same kind of work.",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "“Okay, I'll admit, maybe I have been slacking off a bit at work. I guess I need to make sure I actually deserve more money,” says NAME.",
      [SpellStrength.NORMAL]: "Suddenly, a ringtone sounds from NAME's pocket. “Hi boss … Yes … Thanks, see you in the morning!” And to you, SUBJECT_PRONOUN says: “I got a raise!”",
      [SpellStrength.STRONG]: "Suddenly, a ringtone sounds from NAME's pocket. “Hi boss … Promoted? To <em>your</em> position?”",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "There is a faint sound of falling coins.",
      [SpellStrength.NORMAL]: "NAME's phone buzzes. “Hmm, someone texted me that I got a raise. Must be the wrong number. I don't even have a job.”",
      [SpellStrength.STRONG]: "“Hey, I just got an email,” NAME announces. “It's from a Nigerian prince. He wants to pay me 9 million!”",
    },
  },
  [Spell.LUCK]: {
    name: 'luck',
    category: SpellCategory.MONEY,
    personAdjectives: ['shabby', 'seedy'],
    reasons: [
      "I just can't seem to hold on to a job, so I've invested all my savings into lottery tickets.",
      "I found this lottery ticket on the street. Now I want it to win.",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "NAME checks the lottery outcomes on POSSESSIVE_PRONOUN phone. “Hey, I've got all the right numbers, just in the wrong order. Isn't that a weird coincidence?”",
      [SpellStrength.NORMAL]: "NAME checks the lottery outcomes on POSSESSIVE_PRONOUN phone. “6 out of 8 numbers match! That pays the rent for at least two months!”",
      [SpellStrength.STRONG]: "The door bursts open, and a camera crew rushes in. “Do we have our winner?!” the presenter yells. “Oh yes we do! It's NAME!” You patiently wait in a corner while they interview OBJECT_PRONOUN, hand OBJECT_PRONOUN a big cheque, and leave.",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "“I'm feeling pretty lucky all of a sudden!” says NAME. “Pity I didn't buy any lottery tickets.”",
      [SpellStrength.NORMAL]: "A sudden gust of wind throws the door open, and a $20 note blows in. NAME snatches it out of the air.",
      [SpellStrength.STRONG]: "NAME seems to be pulled sideways off POSSESSIVE_PRONOUN chair, POSSESSIVE_PRONOUN suddenly bulging pocket making a loud thud as it hits the floor. It's overflowing with coins.",
    },
  },
  [Spell.JOB]: {
    name: 'job',
    category: SpellCategory.MONEY,
    personAdjectives: ['unkempt', 'ungroomed'],
    reasons: [
      "I really need a job. I'm flat out broke.",
      "I have a really important interview tomorrow. It's for my dream job!",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "“I'm suddenly feeling much better. If I pull myself together, I should be able to land at least some kind of job and work my way up from there,” NAME says.",
      [SpellStrength.NORMAL]: "NAME's phone rings. “Hi, it's NAME. … What, all of them?!” It seems like all other candidates have dropped out of the interview process!",
      [SpellStrength.STRONG]: "Two men wearing black suits and sunglasses enter the shop. “Please come with us, NAME,” one of them says in a level voice. “The highest echelons of government have specifically requested you to fulfil a top secret assignment. There is no risk to your personal safety, and you will be well reimbursed.”",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "You wonder what it would be like to take on NAME as an apprentice, but quickly discard the thought.",
      [SpellStrength.NORMAL]: "“Hey, would you like to work with me? You'd be my first employee,” you say, before you know what's come over you.",
      [SpellStrength.STRONG]: "NAME's phone buzzes, once, twice, three times. “All messages on LinkedIn. Seems like suddenly everyone wants to hire me. But I already have a job that I love!”",
    },
  },
  [Spell.WEIGHT_LOSS]: {
    name: 'weight loss',
    category: SpellCategory.HEALTH,
    personAdjectives: ['fat', 'obese', 'heavy'],
    reasons: [
      "I feel so gross about myself.",
      "I don't think it's healthy for me to be this big.",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "“Actually, why don't I just improve my diet and start exercising?” NAME wonders. “Otherwise I'll just end up back where I started.”",
      [SpellStrength.NORMAL]: "With a slurping sound, NAME's body seems to shrink a size or two.",
      [SpellStrength.STRONG]: "With a sound like a deflating balloon, NAME's belly shrinks, POSSESSIVE_PRONOUN arms become thinner, and POSSESSIVE_PRONOUN face becomes almost unrecognisable.",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "“Why am I feeling so light all of a sudden?” NAME wonders.",
      [SpellStrength.NORMAL]: "“Huh, I feel so much lighter! Wait, I <em>am</em> lighter! But my weight was fine!”",
      [SpellStrength.STRONG]: "With a disgusting sound, NAME's body and limbs shrink until SUBJECT_PRONOUN is not much more than skin over bone. “What?!” SUBJECT_PRONOUN exclaims. “Oh well, I guess I should just eat some more sweet stuff for a while. Not too bad!”",
    },
  },
  [Spell.STRENGTH]: {
    name: 'strength',
    category: SpellCategory.HEALTH,
    personAdjectives: ['slight', 'slender', 'thin', 'skinny'],
    reasons: [
      "I wish I was stronger. I'm sure I would get all the attention!",
      "I'm the laughing stock of my rugby club.",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "“Hey, I feel the sudden urge to start lifting weights!” NAME says. “That'll get me there!”",
      [SpellStrength.NORMAL]: "NAME's clothes seem to tighten around POSSESSIVE_PRONOUN body. “Hey, hello muscles!” SUBJECT_PRONOUN exclaims.",
      [SpellStrength.STRONG]: "With the disgusting sound of cracking bone, NAME's shoulders suddenly become twice as massive. There's the sound of tearing fabric, and NAME's biceps pop out of POSSESSIVE_PRONOUN sleeves. NAME gapes at them in astonishment.",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "“Wow, I feel like I could lift the world!” NAME says.",
      [SpellStrength.NORMAL]: "NAME straightens POSSESSIVE_PRONOUN back and slams a fist into the table.",
      [SpellStrength.STRONG]: "NAME clenches POSSESSIVE_PRONOUN jaw, flexes POSSESSIVE_PRONOUN muscles and prepares for a karate chop on your expensive mahogany table. You just barely manage to stop OBJECT_PRONOUN.",
    },
  },
  [Spell.HEALING]: {
    name: 'healing',
    category: SpellCategory.HEALTH,
    personAdjectives: ['frail', 'pale', 'feeble', 'sickly'],
    reasons: [
      "I want to get back on my feet.",
      "I'm feeling so weak.",
    ],
    rightTexts: {
      [SpellStrength.WEAK]: "“I'm feeling better already,” says NAME. “But I think I'll also go and see a doctor.”",
      [SpellStrength.NORMAL]: "A rush of a healthy pink colour comes to NAME's cheeks. “Hey, my appetite is back! Do you have anything to eat?”",
      [SpellStrength.STRONG]: "In just a few seconds, a metamorphosis seems to take place. NAME transforms from a sickly person who can barely stay upright in their seat into a strong, blushing, healthy person.",
    },
    wrongTexts: {
      [SpellStrength.WEAK]: "“I'm feeling so healthy all of a sudden! But that was never my problem,” NAME says.",
      [SpellStrength.NORMAL]: "A rush of a healthy pink colour comes to NAME's cheeks.",
      [SpellStrength.STRONG]: "“Hey, my nose just stopped running,” NAME says.",
    },
  },
}

interface IncantationData {
  text: string
}

const INCANTATIONS: Record<Incantation, IncantationData> = {
  [Incantation.ALAKAZAM]: {
    text: "alakazam",
  },
  [Incantation.HOCUS_POCUS]: {
    text: "hocus pocus",
  },
  [Incantation.SIM_SALA_BIM]: {
    text: "sim sala bim",
  },
}

function mergeEffect(into: Effect, e: Effect) {
  if (def(e.spellCategory)) {
    into.spellCategory = def(into.spellCategory) ? null : e.spellCategory
  }
  if (def(e.spellIndex)) {
    into.spellIndex = def(into.spellIndex) ? null : e.spellIndex
  }
  if (def(e.spellStrength)) {
    into.spellStrength = def(into.spellStrength) ? null : e.spellStrength
  }
}

function resolveSpell(category: SpellCategory, index: number) {
  let spells = enumValues<Spell>(Spell).filter((spell) => SPELLS[spell].category == category)
  spells.sort()
  return spells[index]
}

export class Game {
  private readonly state: State
  private readonly io: IO

  constructor(state: State, io: IO) {
    this.state = state
    this.io = io
  }

  public statusLine(): string {
    const items = []
    if (this.state.day !== null) {
      items.push(`<div class="day">Day ${this.state.day}</div>`)
    }
    if (this.state.money !== null) {
      items.push(`<div class="money">$${this.state.money}</div>`)
    }
    for (const item of enumValues<Item>(Item)) {
      const value = this.state.inventory[item]
      if (typeof value === 'number') {
        items.push(`<div class="inventory">${capitalize(ITEMS[item].name)}: ${value}</div>`)
      }
    }
    return items.join('')
  }

  public start() {
    if (FLAGS.skipIntro) {
      this.findIngredients()
      return
    }

    this.io.par(
        "“Well, OK, it was nice working with you all,” you say with a half-hearted smile, as you turn towards the door.",
        "It's the third time you've been fired in two months. Even with these crappy jobs, you can barely make ends meet, what with rent being what it is in this town. There is only one more job opening in town that you haven't tried, which is at the local call centre. You can't imagine anything more dreadful than serving customer complaints for ten hours a day, six days a week.")
    this.io.ask(
        opt("Let's go home", 'goHome'))
  }

  private goHome() {
    this.io.par(
        "As you walk down the street, you find yourself wishing that life was different. More exciting, more… magical.",
        "Wait, “magical”? Suddenly you are reminded of that course in basic spellcasting that you took years ago. The teacher wasn't exactly great, and the course had to be cancelled halfway because she had accidentally turned herself into a toad, but you did seem to have a knack for it. Sadly that, plus a vague promise of unlimited riches, is about all you can remember.",
        "Back home, you immediately head for the attic and start rummaging through boxes. It doesn't take long to find the old course guidebook. Not much is left of it after your classmate set it on fire with a supposedly harmless illumination spell, but parts of the introduction are still readable.")
    this.io.ask(
        opt('Read the guidebook', 'readIntroduction'))
  }

  private readIntroduction() {
    this.io.par(
        "“… into types: interpersonal spells, material spells and health spells, to name just a few. Each type of spell is associated with certain ingredients, but which these are depends on a mysterious concept called the ‘world seed’ and must be determined empirically.”",
        "“… thing to know is that every spell must have a target, for example a person or animal. It's worth noting that, for reasons scholars are still debating, spells cannot be targeted at oneself.”",
        "Well, so much for that. You can't just magic yourself richer, even if you knew how.",
        "But… what about others? What if you could make <em>them</em> rich, or famous, or healthy, and have them pay you for it?",
        "You congratulate yourself on your entrepreneurial spirit. You recall that you have some basic spell ingredients around here that could help you get started…")
    this.io.ask(
        opt('Search for ingredients', 'findIngredients'))
  }

  private findIngredients() {
    const startItems = []
    for (let i = 0; i < 2; i++) {
      let item
      do {
        item = randomElement(enumValues<Item>(Item))
      } while (!(typeof this.state.itemEffects[item].spellCategory !== 'undefined' && startItems.indexOf(item) < 0))
      startItems.push(item)
      this.state.inventory[item] = 5
    }
    this.io.par(
        `You found some ${startItems.map((item) => ITEMS[item].longNamePlural).join(' and some ')}.`,
        "You make a nice sign over the door of your house, saying <em>Magic Spells While You Wait</em>. You decorate your living room with second-hand carpets and mystical symbols. Finally, you put a large mahogany table in the middle of the room, with a chair on either side of it.",
        "A week later, you are ready to open your very own…")
    this.io.write(
        '<h1>Spell Shop</h1>')
    this.io.par(
        `You invest your last <strong>$${START_MONEY}</strong> and decide you'll try this business for <strong>${NUM_DAYS} days</strong>. If you've made <strong>$${TARGET_MONEY}</strong> by the end of that period, you'll declare it a success.`)
    this.io.ask(
        opt('Open the doors!', 'openShop'))
  }

  private openShop() {
    this.state.day = 0
    this.state.money = START_MONEY
    this.startDay()
  }

  private startDay() {
    this.state.day = (this.state.day || 0) + 1
    this.io.write(
        `<h2>Day ${this.state.day}</h2>`)
    if (this.state.day == 1) {
      this.io.par(
          "You have barely opened the doors before some people wander in. Your first customers!",
          "The excitement fades somewhat as you realise that you haven't really thought this part through. Oh well, you'll just have to learn as you go along.")
    } else {
      this.io.par(
          randomElement([
            "Almost as soon as you open your shop for business the next day, several customers come in.",
            "The next day, a handful of customers appear around lunchtime.",
            "The next day turns out to be a slow day. But eventually, some more customers appear.",
          ]))
    }
    this.generateCustomers()
    this.nextCustomer()
  }

  private generateCustomers() {
    const count = this.state.day == 1 ? 3 : 2 + Math.floor(Math.random() * 3)
    for (var i = 0; i < count; i++) {
      const gender = weightedRandomElement([Gender.MALE, Gender.FEMALE, Gender.OTHER], [49, 49, 2])
      const firstName = randomElement(
          gender == Gender.MALE ? MALE_NAMES :
          gender == Gender.FEMALE ? FEMALE_NAMES :
          MALE_NAMES.concat(FEMALE_NAMES))
      const surname = randomElement(SURNAMES)
      const spells = enumValues<Spell>(Spell)
      const spellWeights = []
      for (let i = 0; i < spells.length; i++) {
        spellWeights[i] = Math.max(1, 4 - this.state.customersHelpedWithSpell[spells[i]])
      }
      const spell = weightedRandomElement(
          enumValues<Spell>(Spell),
          spellWeights)
      const spellCategory = SPELLS[spell].category
      const adjective = randomElement(SPELLS[spell].personAdjectives)
      const appearance = `${adjective} ${gender == Gender.MALE ? 'man' : gender == Gender.FEMALE ? 'woman' : 'person'}`
      const apparel = randomApparel(gender)
      const reason = randomElement(SPELLS[spell].reasons)
      const significantOtherGender =
          gender == Gender.MALE ? weightedRandomElement([Gender.MALE, Gender.FEMALE, Gender.OTHER], [15, 83, 2]) :
          gender == Gender.FEMALE ? weightedRandomElement([Gender.MALE, Gender.FEMALE, Gender.OTHER], [83, 15, 2]) :
          weightedRandomElement([Gender.MALE, Gender.FEMALE, Gender.OTHER], [30, 30, 20])
      const significantOtherName = randomElement(
          significantOtherGender == Gender.MALE ? MALE_NAMES :
          significantOtherGender == Gender.FEMALE ? FEMALE_NAMES :
          MALE_NAMES.concat(FEMALE_NAMES))
      this.state.customers.push({ gender, firstName, surname, appearance, apparel, spell, reason, significantOtherName, significantOtherGender, failures: 0 })
    }
    this.state.firstCustomerOfDay = true
  }

  private nextCustomer() {
    if (this.isBankrupt()) {
      this.state.ended = true
      this.io.par(
          "<hr>",
          "Uh-oh… it seems you've run out of supplies and don't have enough money to buy more. This is not the hallmark of a good business.",
          "You close the shop for the rest of the day. The next day, you start drafting a letter of application for the job at the call centre.")
      this.theEnd()
      return
    }
    if (this.state.customers.length == 0) {
      this.io.par("The shop is empty. Nobody else comes in for the rest of the day.")
      if (this.state.day == NUM_DAYS) {
        this.endGame()
      } else {
        this.endDay()
      }
      return
    }
    this.io.par(
        `Which customer would you like to help ${this.state.firstCustomerOfDay ? 'first' : 'next'}?`)
    const opts = this.state.customers.map((customer, index) => opt(capitalize(`${addIndefiniteArticle(customer.appearance)} ${customer.apparel}`), 'helpCustomer', index))
    this.io.ask(...opts)
  }

  private helpCustomer(index: number) {
    const customer = this.state.customers[index]
    this.state.currentCustomer = customer
    this.state.customers.splice(index, 1)
    this.state.firstCustomerOfDay = false
    this.io.par(
        `The ${customer.appearance} introduces ${objectPronoun(customer.gender)}self as ${customer.firstName} ${customer.surname}.`,
        randomElement([
          "“What can I do for you?” you ask.",
          "“What can I do for you today?” you ask.",
          "“How may I help you?” you ask.",
          "“How may I help you today?” you ask.",
        ]),
        `“I need ${indefiniteArticle(SPELLS[customer.spell].name)} <strong>${SPELLS[customer.spell].name} spell</strong>,” ${customer.firstName} says. “${formatPerson(customer.reason, customer)}”`,
        randomElement([
          "“I'll do my best,” ",
          "“I'll do what I can,” ",
        ]) +
        randomElement([
          "you answer",
          "you reply",
          "you say",
        ]) +
        randomElement([
          ", with a hint of doubt in your voice.",
          ", hoping your lack of confidence is not too obvious.",
          ", trying to mask your uncertainty with a smile.",
          " doubtfully.",
          " a bit uncertainly.",
          ", frowning.",
          ", feebly.",
        ]) +
        ` You write “${customer.firstName} ${customer.surname}” on a piece of paper and place it in the middle of the table.`)
    this.state.currentItems = []
    this.chooseItems()
  }

  private chooseItems() {
    const opts = []
    if (this.state.currentItems.length < 2) {
      for (const item of enumValues<Item>(Item)) {
        const value = this.state.inventory[item]
        if (typeof value === 'number' && value > 0 && this.state.currentItems.indexOf(item) < 0) {
          opts.push(opt(`Add ${addIndefiniteArticle(ITEMS[item].longName)}`, 'place', item))
        }
      }
    } else {
      this.io.par("That's quite enough items. Time to cast the spell…")
    }
    for (const incantation of enumValues<Incantation>(Incantation)) {
      opts.push(opt(`“${capitalize(INCANTATIONS[incantation].text)}!”`, 'incant', incantation))
    }
    this.io.ask(...opts)
  }

  private place(item: Item) {
    (<number>this.state.inventory[item])--
    const wasThere = this.state.currentItems.indexOf(item) >= 0
    this.state.currentItems.push(item)
    this.io.par(randomElement(ITEMS[item].useTexts))
    this.chooseItems()
  }

  private incant(incantation: Incantation) {
    this.io.par(
        `“${capitalize(INCANTATIONS[incantation].text)}!”, you ` +
        randomElement(['incant', 'say', 'exclaim', 'recite']) +
        ", with a " +
        randomElement(['booming', 'weak', 'feeble', 'wavering', 'quavering', 'loud']) +
        " voice.")

    const effect: Effect = {}
    for (const item of this.state.currentItems) {
      const itemEffect = this.state.itemEffects[item]
      mergeEffect(effect, itemEffect)
    }
    mergeEffect(effect, this.state.incantationEffects[incantation])
    console.log(`Effect: ${JSON.stringify(effect)}`)

    const customer = this.state.currentCustomer!

    const hasCategory = typeof effect.spellCategory === 'number'
    const hasIndex = typeof effect.spellIndex === 'number'
    const hasStrength = typeof effect.spellStrength === 'number'
    let didCastSpell = false
    let didCastRightSpell = false
    let payment = null
    let spell = null
    let strength = null
    if (hasCategory && hasIndex) {
      strength = hasStrength ? effect.spellStrength! : SpellStrength.WEAK
      spell = resolveSpell(effect.spellCategory!, effect.spellIndex!)
      didCastSpell = true
      if (spell == customer.spell) {
        didCastRightSpell = true
        payment = SPELL_STRENGTHS[strength].payment
        this.io.par(
            formatPerson(SPELLS[spell].rightTexts[strength], customer))
      } else {
        this.io.par(
            formatPerson(SPELLS[spell].wrongTexts[strength], customer))
      }
      this.io.par(
          `It looks like you successfully cast a <strong>${SPELL_STRENGTHS[strength].adjective} ${SPELLS[spell].name} spell</strong>!`)
    } else if (effect.spellCategory === null || effect.spellStrength === null) {
      if (this.state.currentItems.length >= 2) {
        const items =
          this.state.currentItems[0] == this.state.currentItems[1] ?
          `${ITEMS[this.state.currentItems[0]].usedNamePlural}` :
          `${ITEMS[this.state.currentItems[0]].usedName} and the ${ITEMS[this.state.currentItems[1]].usedName}`
        this.io.par(
            `A loud crack sounds, and an arc of lightning flashes up between the ${items}. Both go up in smoke. You vaguely remember from your course that this is not a good outcome.`)
      } else {
        // Should not happen.
        this.io.par("The ground shakes. It seems like it's unclear what kind of spell you were trying to perform.")
      }
    } else if (hasStrength) {
      if (this.state.currentItems.length == 1) {
        const item = this.state.currentItems[0]
        const strength = effect.spellStrength!
        switch (strength) {
          case SpellStrength.WEAK:
            this.io.par(
                `With a sound resembling a weak sigh, the ${ITEMS[item].usedName} crumbles into ashes. Nothing else happens.`)
            break
          case SpellStrength.NORMAL:
            this.io.par(
                `A crack sounds as the ${ITEMS[item].usedName} bursts wholesale into flame. But nothing else happens.`)
            break
          case SpellStrength.STRONG:
            this.io.par(
                `There is a powerful bang as the ${ITEMS[item].usedName} explodes in a cloud of smoke. But nothing else happens.`)
            break
          default:
            assertNever(strength)
        }
      } else {
        // Should not happen.
        this.io.par("The ground shakes. It seems like it's unclear what kind of spell you were trying to perform.")
      }
    } else {
      this.io.par(
          randomElement([
            "Nothing happens. Did you really expect anything else with so little effort?",
            "Nothing happens. What else did you expect?",
            "Nothing happens. Obviously.",
            "Nothing happens. You vaguely remember that there's more to magic than just words.",
            "Nothing happens. Maybe you'll need to put in some more effort.",
          ]))
    }

    this.state.currentItems = []

    if (didCastRightSpell) {
      const finish = randomElement([
        "Another customer served!",
        "That's one happy customer!",
        "You feel like you're getting the hang of this!",
        "Maybe magicking isn't so hard after all!",
      ])
      const greeting = randomElement(["Goodbye!", "Bye!", "See you!"])
      switch (strength!) {
        case SpellStrength.WEAK:
          this.io.par(
              "“" +
              randomElement([
                `Not too bad. I guess I'd have preferred something stronger, but here's <strong>$${payment}</strong> for your trouble.`,
                `I had my doubts when I walked in here, but you made me feel better. Here, have <strong>$${payment}</strong>. Maybe start working on some stronger spells next?`
              ]) + " " + greeting + "” " +
              randomElement([
                "Another customer served!",
                "You feel like you're getting the hang of this!",
              ]))
          break
        case SpellStrength.NORMAL:
          this.io.par(
              "“" +
              randomElement([
                `I knew you could do it! Here's <strong>$${payment}</strong>. Keep the change.`,
                `You did a great job! Here's <strong>$${payment}</strong>. Keep the change.`,
              ]) + " " + greeting + "” " +
              randomElement([
                "That's one happy customer!",
                "Maybe magicking isn't so hard after all!",
              ]))
          break
        case SpellStrength.STRONG:
          this.io.par(
              "“" +
              randomElement([
                `This is fantastic! A life-changer! Thank you so much! I'll pay you extra for that strength! Here's <strong>$${payment}</strong>.`,
                `Thank you so much! This exceeds my wildest dreams! Such a strong spell is surely worth <strong>$${payment}</strong>.`,
              ]) + " " + greeting + "” " +
              randomElement([
                "You congratulate yourself on your magical talents.",
                "If you keep going like this, you'll have a pretty decent income!",
              ]))
          break
      }
      this.state.money = (this.state.money || 0) + payment!
      this.state.customersHelpedWithSpell[spell!]++
      this.nextCustomer()
    } else {
      customer.failures++
      if (customer.failures >= 3) {
        this.io.par(
            randomElement([
              `“That's it. I'm out!”`,
              `“I'm done with this!”`,
              `“This is nonsense! I'm never coming back here!”`,
              `“I've never seen such terrible spellcasting in my life!”`,
            ]) + " " +
            randomElement([
              `${customer.firstName} says as ${subjectPronoun(customer.gender)} gets up and leaves.`,
              `shouts ${customer.firstName}, slamming the door behind ${objectPronoun(customer.gender)}.`,
              `exclaims ${customer.firstName}, and storms out the door.`,
              `yells ${customer.firstName} as ${subjectPronoun(customer.gender)} rushes out of your shop.`,
            ]))
        this.nextCustomer()
      } else {
        if (didCastSpell) {
          this.io.par(
              randomElement([
                `“That's nice. But it's not the <strong>${SPELLS[customer.spell].name} spell</strong> that I asked for,”`,
                `“Could you please just cast the <strong>${SPELLS[customer.spell].name} spell</strong> that I requested?”`,
                `“I didn't order that! I ordered a <strong>${SPELLS[customer.spell].name} spell</strong>!”`,
                `“I asked for a <strong>${SPELLS[customer.spell].name} spell</strong>, not this nonsense!”`,
              ]) + ` says ${customer.firstName}.`)
        } else {
          this.io.par(
              randomElement([
                `${customer.firstName} blinks at you expectantly.`,
                `${customer.firstName} stares at you with a faint expression of amusement.`,
                `${customer.firstName} looks at you hopefully.`,
                `${customer.firstName} frowns.`,
                `${customer.firstName} gives you a sceptical look.`,
              ]))
        }
        this.chooseItems()
      }
    }
  }

  private isBankrupt(): boolean {
    let count = 0
    for (const item of enumValues<Item>(Item)) {
      count += (this.state.inventory[item] || 0)
    }
    return count == 0 && (this.state.money || 0) < 10
  }

  private endDay() {
    this.io.ask(
        opt('Go to the store to stock up on supplies', 'goToStore'))
  }

  private goToStore() {
    this.io.par(
        "“" +
        randomElement([
          `Good evening, and welcome to <em>${this.state.shopkeeperName}'s Magical Items</em>!`,
          `Welcome to <em>${this.state.shopkeeperName}'s Magical Items</em>!`,
        ]) + " " +
        randomElement([
          "How can I help you?",
          "What can I do for you tonight?",
          "How may I help you?",
          "What is it you are looking for today?",
        ]) + "” " +
        randomElement([
          ` says ${this.state.shopkeeperName}, the shopkeeper.`,
          ` asks ${this.state.shopkeeperName}, the shopkeeper.`,
          ` ${this.state.shopkeeperName}, the shopkeeper, says.`,
          ` ${this.state.shopkeeperName}, the shopkeeper, asks.`,
        ]))
    this.chooseStoreItem()
  }

  private chooseStoreItem() {
    const opts = []
    for (const item of enumValues<Item>(Item)) {
      const price = this.state.itemPrices[item]
      if (price <= (this.state.money || 0)) {
        opts.push(opt(`Buy ${ITEMS[item].buyCount} ${ITEMS[item].longNamePlural} for $${price}`, 'buyItem', item))
      }
    }
    opts.push(opt('Leave the store', 'leaveStore'))
    this.io.ask(...opts)
  }

  private buyItem(item: Item) {
    this.state.money = (this.state.money || 0) - this.state.itemPrices[item]
    this.state.inventory[item] = (this.state.inventory[item] || 0) + ITEMS[item].buyCount
    this.io.par(
        "“" +
        randomElement([
          "Here you go.",
          "Here you are.",
        ]) + " " +
        randomElement([
          "Anything else?",
          "Anything else you need?",
          "Anything else I can help you with?",
          "Will that be all?",
        ]) + "”, " +
        randomElement([
          `${this.state.shopkeeperName} says`,
          `says ${this.state.shopkeeperName}`,
          `${this.state.shopkeeperName} asks`,
          `asks ${this.state.shopkeeperName}`,
        ]) +
        randomElement([
          "", "", "",
          " with a smile",
          " with a friendly smile",
        ]) + ".")
    this.chooseStoreItem()
  }

  private leaveStore() {
    this.io.par(
        "“" +
        randomElement([
          `Thank you for your visit today!`,
          `Thank you for your purchase!`,
          `See you again soon!`,
        ]) + "” " +
        randomElement([
          `says ${this.state.shopkeeperName}`,
          `${this.state.shopkeeperName} says`,
        ]) + " " +
        randomElement([
          `as you leave the store and head for your bed.`,
          `as you leave the store and head home.`,
        ]))
    this.startDay()
  }

  private endGame() {
    this.state.ended = true
    this.io.par('<hr>')
    if ((this.state.money || 0) >= TARGET_MONEY) {
      this.io.par(
          `Phew! It's been a long ${this.state.day} days! At the end of the last day, you count your money. You've made <strong>$${this.state.money}</strong> in this period, which means your business is a success! Congratulations!`)
    } else {
      this.io.par(
          `It's been a long and interesting ${this.state.day} days for sure, but it hasn't been enough. You've made only <strong>$${this.state.money}</strong>, which is less than your target of $${TARGET_MONEY}, making your business a failure.`,
          'The next day, you start drafting a letter of application for the job at the call centre.')
    }
    this.theEnd()
  }

  private theEnd() {
    this.io.write('<p class="end">❧ The End ☙</p>')
    this.io.write('<p class="center"><a class="restart">Play again?</a></p>')
    this.io.ask()
  }
}
