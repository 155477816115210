import '../scss/index.scss'
import { Game, IO, Option } from './game'
import { State, newState } from './state'
import { FLAGS } from './debug'
import * as $ from 'jquery'

class Main implements IO {
  private readonly status = $('.status')
  private readonly scroll = $('.scroll')
  private readonly input = $('.input')
  private readonly output = $('.output')
  private state!: State
  private game!: Game

  constructor() {
    try {
      if (FLAGS.newGame) {
        throw new Error()
      }
      this.loadGame()
    } catch (ex) {
      this.newGame()
    }

    $(document).on('click', '.restart', (e) => { e.preventDefault(); this.restart(); })
    this.input.on('click', 'a.option', (e) => {
      e.preventDefault();
      this.handleOption($(e.target))
    })
  }

  private newGame() {
    this.state = newState()
    this.game = new Game(this.state, this)
    this.output.empty()
    this.game.start()
    this.updateStatusLine()
    console.log('New game started')
    this.saveGame()
  }

  private loadGame() {
    const state = window.localStorage.getItem("state")
    const output = window.localStorage.getItem("output")
    const input = window.localStorage.getItem("input")
    if (!state || !output || !input) throw Error()
    this.state = JSON.parse(state)
    this.output.html(output)
    this.input.html(input)
    this.game = new Game(this.state, this)
    this.updateStatusLine()
    this.scroll.prop({ scrollTop: this.scroll.prop('scrollHeight') })
    console.log('Game loaded')
  }

  private saveGame() {
    window.localStorage.setItem("state", JSON.stringify(this.state))
    window.localStorage.setItem("output", this.output.html())
    window.localStorage.setItem("input", this.input.html())
    console.log('Game saved')
  }

  private restart() {
    if (this.state.ended || window.confirm('This will restart the game from the beginning; all progress will be lost and knowledge will be shuffled. Are you sure?')) {
      this.newGame()
    }
  }

  private updateStatusLine() {
    this.status.html(this.game.statusLine())
  }

  private scrollToEnd() {
    this.scroll.animate({ scrollTop: this.scroll.prop('scrollHeight') - this.scroll.prop('clientHeight') + 1 }, { duration: 400, queue: false })
  }

  public write(html: string) {
    this.output.append(html)
  }

  public par(...html: string[]) {
    for (const par of html) {
      this.write(`<p>${par}</p>`)
    }
  }

  public ask(...options: Option[]) {
    this.input.html('')
    for (const option of options) {
      const a = $(`<a class="option">${option.html}</a>`)
        .attr('data-method', option.method)
        .attr('data-args', JSON.stringify(option.args))
      this.input.append($('<div>').append(a))
    }
    this.scrollToEnd()
  }

  private handleOption(a: JQuery<any>) {
    const method = a.attr('data-method')!
    let args = JSON.parse(a.attr('data-args')!)
    const func = (<any>this.game)[method]
    if (!$.isFunction(func)) {
      console.error(`Method ${method} does not exist`)
      return
    }

    this.input.empty()
    this.write(`<p class="choice">${a.html()}</p>`)
    this.updateStatusLine()

    console.log(`${method}(${args.join(', ')})`)
    func.apply(this.game, args)
    this.updateStatusLine()

    this.saveGame()
  }
}

window.addEventListener('load', () => {
  new Main()
})
