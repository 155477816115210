import { shuffle, enumValues, randomElement, randomAssignment, constMap } from './utils'
import { MALE_NAMES, FEMALE_NAMES } from './names'
// Only pure JSONable types here!

export enum Gender {
  MALE,
  FEMALE,
  OTHER,
}

export enum SpellCategory {
  LOVE,
  MONEY,
  HEALTH,
}

export enum SpellStrength {
  WEAK,
  NORMAL,
  STRONG,
}

export enum Spell {
  CRUSH,
  RELATIONSHIP,
  MARRIAGE,
  SALARY,
  LUCK,
  JOB,
  WEIGHT_LOSS,
  STRENGTH,
  HEALING,
}

export enum Item {
  CANDLE,
  INCENSE,
  ESSENTIAL_OIL,
  // CRYSTAL,
  // COIN,
  // CRUCIFIX,
  ROSEMARY,
  GARLIC,
  SAGE,
}

export enum Incantation {
  ALAKAZAM,
  HOCUS_POCUS,
  SIM_SALA_BIM,
}

export interface Effect {
  spellCategory?: SpellCategory | null
  spellIndex?: number | null
  spellStrength?: SpellStrength | null
}

export type Inventory = {
  [K in keyof typeof Item]?: number
}

export interface Customer {
  gender: Gender
  firstName: string
  surname: string
  appearance: string
  apparel: string
  spell: Spell
  reason: string
  significantOtherName: string
  significantOtherGender: Gender
  failures: number
}

export interface State {
  itemEffects: Record<Item, Effect>
  incantationEffects: Record<Incantation, Effect>
  inventory: Inventory
  totalCustomers: number
  customers: Customer[]
  firstCustomerOfDay: boolean
  currentCustomer: Customer | null
  customersHelpedWithSpell: Record<Spell, number>
  currentItems: Item[]
  day: number | null
  money: number | null
  ended: boolean,
  shopkeeperName: string,
  itemPrices: Record<Item, number>
}

export function newState(): State {
  const itemEffects = randomAssignment<Item, Effect>(
      enumValues<Item>(Item),
      enumValues<SpellCategory>(SpellCategory).map((spellCategory) => ({ spellCategory } as Effect)).concat(
        enumValues<SpellStrength>(SpellStrength).map((spellStrength) => ({ spellStrength } as Effect))))
  const incantationEffects = randomAssignment<Incantation, Effect>(
      enumValues<Incantation>(Incantation),
      [0, 1, 2].map((spellIndex) => ({ spellIndex } as Effect)))
  const itemPrices = randomAssignment<Item, number>(
      enumValues<Item>(Item),
      [10, 10, 15, 15, 20, 20])

  return {
    itemEffects: itemEffects,
    incantationEffects: incantationEffects,
    inventory: {},
    totalCustomers: 0,
    customers: [],
    firstCustomerOfDay: true,
    currentCustomer: null,
    customersHelpedWithSpell: constMap<Spell, number>(enumValues<Spell>(Spell), 0),
    currentItems: [],
    day: null,
    money: null,
    ended: false,
    shopkeeperName: randomElement(MALE_NAMES.concat(FEMALE_NAMES)),
    itemPrices: itemPrices,
  }
}
